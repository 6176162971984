import $ from "jquery";

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/modal';

import UIBindings from "./ui-bindings";
import { Forms } from "./form";

class PageTimer {
    getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};
        var ms = now - timing.navigationStart;
        return Math.round(ms / 10) / 100;
    }

    logToConsole = function () {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            console && console.info && console.info(`Client loaded in ${loadTime}s`);
        });
    }

    append = function (holder) {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            holder.text(' | LT: ' + loadTime() + 's');
        });
    }
}

class ExternalLinks {
    hostname = document.location.hostname;
    matchRegex = [new RegExp("^https?:\/\/(.*?)" + this.hostname)];
    context = null;

    constructor(selector) {
        this.context = $(selector);
    }

    addLinkTarget() {
        var self = this;
        
        this.context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
            var anchor = $(this);
            var href = anchor.attr('href');
            var isInternalLink = false;

            for (var i = 0; i < self.matchRegex.length; i++) {
                var regex = self.matchRegex[i];
                if (href.match(regex)) {
                    isInternalLink = true;
                }
            }

            if (!isInternalLink) {
                anchor.attr('target', '_blank').addClass('external-link');
            }
        });
    }
}

(new PageTimer()).logToConsole();
(new ExternalLinks($('body'))).addLinkTarget();

const UIBinding = new UIBindings();

UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.share();
UIBinding.bootstrap();

const forms = new Forms();
forms.validate();